import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { RoleType } from '@goatsports/core/data-access';
import { CurrentUserService } from '../services/current-user.service';
import { ROLE_PERMISSIONS } from '../functions/role-permissions';
import { RouteConstants } from '../constants/route.constant';

export const RoleGuard: CanActivateFn = (route, state) => {
  const currentUserService = inject(CurrentUserService);
  const router = inject(Router);
  const userRole = currentUserService.currentUserRole as RoleType;

  if (shouldAccessChat() || shouldAccessProfile()) return true;

  if (userRole === RoleType.Translator) {
    // If the requested URL starts with '/configure', allow access
    if (state.url.startsWith(`/${RouteConstants.CONFIGURE_ROUTE}`)) {
      return true;
    } else {
      // Redirect to '/configure' and prevent access
      router.navigate([`/${RouteConstants.CONFIGURE_ROUTE}`]);
      return false;
    }
  }

  const accessibleRoutes = ROLE_PERMISSIONS[userRole];

  if (accessibleRoutes) {
    const isAccessible = accessibleRoutes.some((routeItem) =>
      state.url.startsWith(routeItem.id!),
    );

    if (isAccessible) {
      return true;
    } else {
      // Redirect to access denied or a default route
      router.navigate([`/${RouteConstants.DASHBOARD_ROUTE}`]);
      return false;
    }
  } else {
    // Handle roles without defined permissions
    router.navigate([`/${RouteConstants.DASHBOARD_ROUTE}`]);
    return false;
  }

  function shouldAccessChat() {
    return (
      (userRole === RoleType.OrganizationAdmin ||
        userRole === RoleType.Coach) &&
      state.url.startsWith(`/${RouteConstants.CHAT_ROUTE}`)
    );
  }

  function shouldAccessProfile() {
    return state.url.startsWith(`/${RouteConstants.PROFILE_ROUTE}`);
  }
};
