import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  inject,
  viewChild,
} from '@angular/core';
import {
  NotificationsListService,
  RemoveNotificationModel,
} from './notifications-list.service';
import { BaseComponent, ScrollDirective } from '@goatsports/shared/util';
import { Subject, exhaustMap, finalize } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import {
  createSpinner,
  hideSpinner,
  showSpinner,
  TooltipModule,
} from '@syncfusion/ej2-angular-popups';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { EmptyNotificationsPipe } from './empty-notifications.pipe';
import { NotificationsListItemComponent } from './notifications-list-item/notifications-list-item.component';
import { FullDateToMonthDatePipe } from '../../pipes/full-date-to-month-date.pipe';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { explicitEffect } from 'ngxtension/explicit-effect';
@Component({
  selector: 'goatsports-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    InfiniteScrollDirective,
    ScrollDirective,
    ListViewModule,
    TooltipModule,
    ButtonModule,
    LoadingSpinnerComponent,
    NotificationsListItemComponent,
    EmptyNotificationsPipe,
    FullDateToMonthDatePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListComponent extends BaseComponent {
  private readonly notificationsListService = inject(NotificationsListService);

  container = viewChild<ElementRef<HTMLDivElement>>('container');

  notifications = this.notificationsListService.notifications;
  status = this.notificationsListService.status;

  isScrollDisabled = this.notificationsListService.isScrollDisabled;

  constructor() {
    super();

    explicitEffect([this.container], ([container]) => {
      if (!container) return;

      createSpinner({
        target: container.nativeElement,
        width: 50,
      });
    });
  }

  override ngOnDestroy() {
    super.ngOnDestroy();

    this.notificationsListService.resetState();
  }

  onScroll() {
    this.notificationsListService.updatePageNumber();
  }

  removeNotification(uid: string, createdDate: string) {
    const element = this.container()!.nativeElement;

    showSpinner(element);

    this.notificationsListService
      .removeNotification({ uid, createdDate })
      .pipe(
        finalize(() => {
          hideSpinner(element);
        }),
      )
      .subscribe();
  }

  clearAllNotifications() {
    const element = this.container()!.nativeElement;

    showSpinner(element);

    this.notificationsListService
      .clearAllNotifications()
      .pipe(
        finalize(() => {
          hideSpinner(element);
        }),
      )
      .subscribe();
  }
}
