<div class="notification">
  <ng-container *transloco="let t">
    <header>
      <h2>{{ t('Notifications') }}</h2>

      <button
        ejs-button
        cssClass="e-btn e-flat clear-btn"
        [disabled]="notifications() | emptyNotifications"
        (click)="clearAllNotifications()"
      >
        {{ t('Clear all') }}
      </button>
    </header>

    @if (status() === LoadingStatus.Loading) {
      <goatsports-loading-spinner />
    } @else {
      <div
        #container
        class="notifications-wrapper"
        [scrollWindow]="false"
        appScrollDirective
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="150"
        [infiniteScrollDisabled]="isScrollDisabled()"
        (scrolled)="onScroll()"
      >
        @for (notification of notifications() | keyvalue; track $index) {
          @if (notification.value.length >= 1) {
            <p>
              {{
                notification.value.length >= 1
                  ? t((notification.key | fullDateToMonthDate).month) +
                    ', ' +
                    (notification.key | fullDateToMonthDate).year
                  : ''
              }}
            </p>
            @for (data of notification.value; track data.uid) {
              <goatsports-notifications-list-item
                [notification]="data"
                (removeNotification)="
                  removeNotification($event, data.createdDateTimeUTC!)
                "
              />
            }
          }
        } @empty {
          <div class="no-data">
            <p>
              {{ t('Any events needing your attention will be shown here.') }}
            </p>
          </div>
        }

        @if (status() === LoadingStatus.LoadingPartial) {
          <goatsports-loading-spinner
            [ngStyle]="{ 'height.px': 40 }"
            [diameter]="30"
            [thickness]="4"
          />
        }
      </div>
    }
  </ng-container>
</div>
