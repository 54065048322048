import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { RouteConstants } from './shared/constants/route.constant';
import { AuthGuard } from './shared/guards/auth.guard';
import { RoleGuard } from './shared/guards/role.guard';
import { unsavedChangesGuard } from './shared/guards/unsaved-changes.guard';
import ShellComponent from './shell/shell.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: RouteConstants.DASHBOARD_ROUTE,
        loadComponent: () => import('./dashboard/dashboard.page'),
      },
      {
        path: RouteConstants.GROUPS_ROUTE,
        children: [
          {
            path: ':uid',
            loadComponent: () =>
              import('./groups/edit-group/edit-group.component'),
            canDeactivate: [unsavedChangesGuard],
          },
          {
            path: '',
            loadComponent: () => import('./groups/groups.component'),
          },
        ],
      },
      {
        path: RouteConstants.ADMINS_ROUTE,
        loadComponent: () => import('./admins/admins.page'),
      },
      {
        path: RouteConstants.ORGANIZATION_SETTINGS_ROUTE,
        loadComponent: () =>
          import('./organization-settings/organization-settings.page'),
      },
      {
        path: RouteConstants.ORGANIZATIONS_ROUTE,
        children: [
          {
            path: ':uid',
            loadComponent: () =>
              import(
                './organizations/single-organization/single-organization.component'
              ),
            children: [
              {
                path: RouteConstants.ORGANIZATION_TEAMS_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-teams/organization-teams.component'
                  ),
              },
              {
                path: RouteConstants.ORGANIZATION_ADMINS_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-admins/organization-admins.component'
                  ),
              },
              {
                path: RouteConstants.ORGANIZATION_CLIENTS_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-clients/organization-clients.component'
                  ),
              },
              {
                path: RouteConstants.ORGANIZATION_COACHES_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-coaches/organization-coaches.component'
                  ),
              },
              {
                path: RouteConstants.ORGANIZATION_PROFILE_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-profile/organization-profile.component'
                  ),
              },
              {
                path: RouteConstants.SETTINGS_ROUTE,
                loadComponent: () =>
                  import(
                    './organizations/single-organization/organization-settings/organization-settings.component'
                  ),
              },
              {
                path: '',
                redirectTo: RouteConstants.ORGANIZATION_PROFILE_ROUTE,
                pathMatch: 'prefix',
              },
            ],
          },
          {
            path: '',
            loadComponent: () =>
              import('./organizations/organizations.component'),
          },
        ],
      },
      {
        path: RouteConstants.CHAT_ROUTE,
        loadComponent: () => import('./chat/chat.component'),
      },
      {
        path: RouteConstants.CLIENTS_ROUTE,
        children: [
          {
            path: ':uid',
            loadChildren: () =>
              import('./clients/single-client/single-client.module').then(
                (m) => m.SingleClientModule,
              ),
          },
          {
            path: '',
            loadComponent: () => import('./clients/clients.component'),
          },
        ],
      },

      {
        path: RouteConstants.SETTINGS_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./settings/settings.module').then(
                (m) => m.SettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_WORKOUT_EXERCISE_ROUTE,
            loadChildren: () =>
              import(
                './settings/workout-exercise-settings/workout-exercise-settings.module'
              ).then((m) => m.WorkoutExerciseSettingsModule),
          },
          {
            path: RouteConstants.SETTINGS_MEALS_ROUTE,
            loadChildren: () =>
              import('./settings/meals-settings/meals-settings.module').then(
                (m) => m.MealsSettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_HABIT_CATEGORIES_ROUTE,
            loadChildren: () =>
              import('./settings/habits-settings/habits-settings.module').then(
                (m) => m.HabitsSettingsModule,
              ),
          },
          {
            path: RouteConstants.SETTINGS_GOALS_METRICS_ROUTE,
            loadChildren: () =>
              import(
                './settings/goals-metrics-settings/goals-metrics-settings.module'
              ).then((m) => m.GoalsMetricsSettingsModule),
          },
        ],
      },

      {
        path: RouteConstants.CONFIGURE_ROUTE,
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./settings/translation/translation.module').then(
                (m) => m.TranslationModule,
              ),
            canActivate: [RoleGuard],
          },
          {
            path: RouteConstants.TRANSLATION_INGREDIENTS_ROUTE,
            loadChildren: () =>
              import(
                './settings/translation/translation-ingredients/translation-ingredients.module'
              ).then((m) => m.TranslationIngredientsModule),
          },

          {
            path: RouteConstants.TRANSLATION_MEALS_ROUTE,
            loadChildren: () =>
              import(
                './settings/translation/translation-meals/translation-meals.module'
              ).then((m) => m.TranslationMealsModule),
          },
        ],
      },

      {
        path: RouteConstants.COACHES_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () => import('./coaches/coaches.component'),
          },
        ],
      },
      {
        path: RouteConstants.TEAMS_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () => import('./teams/teams.component'),
          },
        ],
      },
      {
        path: RouteConstants.EXERCISES_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () => import('./exercises/exercises.component'),
          },
        ],
      },
      {
        path: RouteConstants.WORKOUTS_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () => import('./workouts/workouts.component'),
          },
          {
            path: ':uid',
            loadComponent: () =>
              import('./workouts/single-workout/single-workout.component'),
          },
        ],
      },

      {
        path: RouteConstants.WORKOUTS_ADD_ROUTE,
        children: [
          {
            path: ':uid',
            loadComponent: () =>
              import('./workouts/single-workout/single-workout.component'),
          },
        ],
      },
      {
        path: RouteConstants.WORKOUT_PROGRAMS_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./workout-programs/workout-programs.component'),
          },
          {
            path: ':uid',
            loadComponent: () =>
              import(
                './workout-programs/single-workout-program/single-workout-program.component'
              ),
          },
        ],
      },
      {
        path: RouteConstants.DAILY_WORKOUTS_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () =>
              import('./daily-workouts/daily-workouts.component'),
          },

          {
            path: ':uid',
            loadComponent: () =>
              import(
                './daily-workouts/single-daily-workouts/single-daily-workouts.component'
              ),
          },
        ],
      },

      {
        path: RouteConstants.INGREDIENTS_ROUTE,
        loadComponent: () => import('./ingredients/ingredients.component'),
      },

      {
        path: RouteConstants.MEAL_PLAN_ROUTE,
        children: [
          {
            path: '',
            loadComponent: () => import('./meal-plan/meal-plan.component'),
          },
          {
            path: ':uid',
            loadComponent: () =>
              import('./meal-plan/single-meal-plan/single-meal-plan.component'),
          },
        ],
      },
      {
        path: RouteConstants.DAILY_MEALS_ROUTE,

        children: [
          {
            path: '',
            loadComponent: () => import('./daily-meals/daily-meals.component'),
          },

          {
            path: ':uid',
            loadComponent: () =>
              import(
                './daily-meals/single-daily-meals/single-daily-meals.component'
              ),
          },
        ],
      },

      {
        path: RouteConstants.MEALS_ROUTE,
        loadComponent: () => import('./meals/meals.component'),
      },

      {
        path: RouteConstants.HABITS_ROUTE,
        children: [
          {
            path: RouteConstants.HABITS_ROUTE,
            children: [
              {
                path: '',
                loadComponent: () => import('./habits/habits.component'),
              },
            ],
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            children: [
              {
                path: '',
                loadComponent: () =>
                  import(
                    './habits/habits-scheduler/habits-scheduler.component'
                  ).then((m) => m.HabitsSchedulerComponent),
              },
            ],
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.HABITS_ROUTE,
          },
        ],
      },

      {
        path: RouteConstants.REFLECTIONS_ROUTE,
        children: [
          {
            path: RouteConstants.REFLECTIONS_ROUTE,
            children: [
              {
                path: `${RouteConstants.EDIT_REFLECTION_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: `${RouteConstants.VIEW_REFLECTION_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
              },
              {
                path: RouteConstants.ADD_REFLECTION_ROUTE,
                loadComponent: () =>
                  import(
                    './reflections/reflections/create-edit-reflection/create-edit-reflection.component'
                  ),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: '',
                loadComponent: () =>
                  import('./reflections/reflections/reflections.component'),
              },
            ],
          },
          {
            path: RouteConstants.QUESTIONS_ROUTE,
            loadComponent: () =>
              import('./reflections/questions/questions.component'),
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            loadComponent: () =>
              import(
                './reflections/reflections-scheduler/reflections-scheduler.component'
              ).then((m) => m.ReflectionsSchedulerComponent),
          },

          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.REFLECTIONS_ROUTE,
          },
        ],
      },
      {
        path: `${RouteConstants.BUNDLES_ROUTE}/${RouteConstants.BUNDLES_ROUTE}/:uid`,
        loadComponent: () =>
          import(
            './bundles-shell/bundles/create-edit-bundle/create-edit-bundle.page'
          ),
        canDeactivate: [unsavedChangesGuard],
      },
      {
        path: `${RouteConstants.BUNDLES_ROUTE}/${RouteConstants.BUNDLES_ROUTE}/${RouteConstants.VIEW_BUNDLE_ROUTE}`,
        loadComponent: () =>
          import(
            './bundles-shell/bundles/create-edit-bundle/create-edit-bundle.page'
          ),
      },
      {
        path: RouteConstants.BUNDLES_ROUTE,
        loadComponent: () => import('./bundles-shell/bundles-shell.component'),
        children: [
          {
            path: RouteConstants.BUNDLES_ROUTE,
            children: [
              /* {
                path: `${RouteConstants.EDIT_BUNDLE_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './bundles-shell/bundles/create-edit-bundle/create-edit-bundle.page'
                  ),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: `${RouteConstants.VIEW_BUNDLE_ROUTE}/:uid`,
                loadComponent: () =>
                  import(
                    './bundles-shell/bundles/create-edit-bundle/create-edit-bundle.page'
                  ),
              },
              {
                path: RouteConstants.CREATE_BUNDLE_ROUTE,
                loadComponent: () =>
                  import(
                    './bundles-shell/bundles/create-edit-bundle/create-edit-bundle.page'
                  ),
                canDeactivate: [unsavedChangesGuard],
              }, */
              {
                path: '',
                loadComponent: () =>
                  import('./bundles-shell/bundles/bundles.page'),
              },
            ],
          },
          {
            path: RouteConstants.ORGANIZATIONS_ROUTE,
            loadComponent: () =>
              import('./bundles-shell/organizations/organizations.component'),
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.BUNDLES_ROUTE,
          },
        ],
      },
      {
        path: RouteConstants.QUIZ_ROUTE,

        children: [
          {
            path: RouteConstants.QUIZZES_ROUTE,
            children: [
              {
                path: `${RouteConstants.EDIT_QUIZ_ROUTE}/:uid`,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: `${RouteConstants.VIEW_QUIZ_ROUTE}/:uid`,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: RouteConstants.ADD_QUIZ_ROUTE,
                loadComponent: () =>
                  import('./quiz/quizzes/create-quiz/create-quiz.component'),
                canDeactivate: [unsavedChangesGuard],
              },
              {
                path: '',
                loadComponent: () => import('./quiz/quizzes/quizzes.component'),
              },
            ],
          },
          {
            path: RouteConstants.QUESTIONS_ROUTE,
            loadComponent: () => import('./quiz/questions/questions.component'),
          },

          {
            path: RouteConstants.SCHEDULER_ROUTE,
            loadComponent: () =>
              import('./quiz/quiz-scheduler/quiz-scheduler.component').then(
                (m) => m.QuizSchedulerComponent,
              ),
          },
          {
            path: '**',
            pathMatch: 'full',
            redirectTo: RouteConstants.QUIZZES_ROUTE,
          },
        ],
      },
      {
        path: RouteConstants.PROFILE_ROUTE,
        loadComponent: () => import('./profile/profile.component'),
      },

      {
        path: '**',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
