import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
} from '@angular/core';
import { Router } from '@angular/router';
import { RouteConstants } from '../../../constants/route.constant';
import { ApplicationStateService } from '../../../services/application-state.service';
import { CommonModule } from '@angular/common';
import { TranslocoDirective } from '@jsverse/transloco';
import { TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import {
  NotificationCreatedEvent,
  NotificationType,
} from '@goatsports/core/data-access';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'goatsports-notifications-list-item',
  templateUrl: './notifications-list-item.component.html',
  styleUrls: ['./notifications-list-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    TranslocoLocaleModule,
    TooltipModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsListItemComponent {
  private readonly router = inject(Router);
  private readonly applicationStateService = inject(ApplicationStateService);

  readonly NotificationType = NotificationType;

  readonly notification = input.required<NotificationCreatedEvent>();

  readonly removeNotification = output<string>();

  language = toSignal(this.applicationStateService.languageChanged$, {
    initialValue: 'en',
  });

  navigateToChat() {
    const groupUid = (this.notification().payload as any).groupUid;

    this.applicationStateService.selectedChatGroupUidChanged(groupUid);
    this.router.navigate([RouteConstants.CHAT_ROUTE]);
  }

  navigateToWorkout() {
    const userWorkoutUid = (this.notification().payload as any).userWorkoutUid;

    const path =
      '/' +
      RouteConstants.CLIENTS_ROUTE +
      '/' +
      this.notification().clientUid +
      '/' +
      RouteConstants.CLIENTS_OVERVIEW_ROUTE +
      '/' +
      userWorkoutUid;
    this.router.navigate([path]);
  }

  remove() {
    this.removeNotification.emit(this.notification().uid!);
  }
}
