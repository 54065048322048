<ng-container *transloco="let t">
  @let payload = $any(notification().payload);
  <div
    class="inner-card list-item e-list-wrapper e-list-multi-line e-list-avatar list-row"
  >
    <div class="content-align-center">
      <div class="date">
        <span class="number">{{
          notification().createdDateTimeUTC | date: 'd' : undefined : language()
        }}</span>
        <span class="day">{{
          notification().createdDateTimeUTC
            | date: 'MMM' : undefined : language()
        }}</span>
      </div>
      <div class="notification-text">
        <span
          >{{
            notification().clientFirstName + ' ' + notification().clientLastName
          }}
        </span>
        <div class="word-break-text">
          @if (notification().type === NotificationType.WorkoutMissed) {
            <div>
              <p>{{ t('Missed last workout') }}</p>
              <div>
                <b class="workout-name" (click)="navigateToWorkout()">{{
                  payload?.workoutName
                }}</b>
              </div>
            </div>
          }

          @if (notification().type === NotificationType.WorkoutMoved) {
            <div>
              <p>
                {{ t('Moved') }}
                {{
                  payload?.movedFromDateTimeUTC > payload?.movedToDateTimeUTC
                    ? t('future')
                    : t('passed')
                }}
                {{ t('workout') }}
              </p>
              <div>
                <b class="workout-name" (click)="navigateToWorkout()">{{
                  payload?.workoutName
                }}</b>
                {{ t('from') }}
                <b>
                  {{
                    payload?.movedFromDateTimeUTC
                      | date: 'MMM d' : undefined : language()
                  }}</b
                >, {{ t('to') }}
                <b>
                  {{
                    payload?.movedToDateTimeUTC
                      | date: 'MMM d' : undefined : language()
                  }}
                </b>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
    <div class="message-icon">
      <span
        style="cursor: pointer; font-size: 24px; color: #fac022"
        class="e-icons e-comment-show remove"
        (click)="navigateToChat()"
      >
      </span>
    </div>
    <div class="remove-icon">
      <ejs-tooltip
        opensOn="Hover"
        id="tooltip"
        [content]="t('Dismiss')"
        target="#dismiss"
        position="BottomLeft"
      >
        <img
          id="dismiss"
          class="remove"
          src="assets/icons/dismiss.svg"
          (click)="remove()"
        />
      </ejs-tooltip>
    </div>
  </div>
</ng-container>
