import { computed, inject, Injectable, signal } from '@angular/core';
import { filter, of, startWith, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs';
import {
  NotificationCreatedEvent,
  NotificationService,
  NotificationType,
} from '@goatsports/core/data-access';
import { ApiEventsService } from '../../services/api-events.service';
import { LoadingStatus } from '@goatsports/shared/util';
import * as _ from 'lodash-es';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface RemoveNotificationModel {
  uid?: string;
  createdDate?: string;
}

type State = {
  notifications: Record<string, NotificationCreatedEvent[]>;
  pageNumber: number;
  hasNext: boolean | undefined;
  status: LoadingStatus;
};

@Injectable({ providedIn: 'root' })
export class NotificationsListService {
  private readonly notificationService = inject(NotificationService);
  private readonly apiEventsService = inject(ApiEventsService);

  private readonly DEFAULT_PAGE_SIZE = 8;

  private readonly INITIAL_STATE: State = {
    notifications: {},
    pageNumber: 1,
    hasNext: undefined,
    status: LoadingStatus.Loading,
  };

  private readonly state = signal<State>(this.INITIAL_STATE);

  // selectors

  notifications = computed(() => this.state().notifications);
  pageNumber = computed(() => this.state().pageNumber);
  hasNext = computed(() => this.state().hasNext);
  status = computed(() => this.state().status);

  isScrollDisabled = computed(
    () => !this.hasNext() || this.status() === LoadingStatus.LoadingPartial,
  );

  // sources

  pageNumber$ = new Subject<number>();

  private notifications$ = this.pageNumber$.pipe(
    startWith(1),
    switchMap((pageNumber) => this.fetchNotifications(pageNumber)),
  );

  private notificationCreated$ =
    this.apiEventsService.notificationCreated$.pipe(
      filter(
        (x) =>
          x.type === NotificationType.WorkoutMissed ||
          x.type === NotificationType.WorkoutMoved,
      ),
    );

  constructor() {
    this.notifications$
      .pipe(takeUntilDestroyed())
      .subscribe((notifications) => {
        this.state.update((state) => ({
          ...state,
          notifications,
          status: LoadingStatus.Success,
        }));
      });

    this.pageNumber$.pipe(takeUntilDestroyed()).subscribe((pageNumber) => {
      this.state.update((state) => ({
        ...state,
        pageNumber,
        status: pageNumber > 1 ? LoadingStatus.LoadingPartial : state.status,
      }));
    });

    this.notificationCreated$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.resetState();
    });
  }

  updatePageNumber() {
    const newPage = this.pageNumber() + 1;

    this.state.update((state) => ({
      ...state,
      pageNumber: newPage,
    }));

    this.pageNumber$.next(newPage);
  }

  /**
   * Call your backend to remove the notification by `uid`.
   * Once successful, remove it from the state so it's no longer displayed.
   */
  removeNotification(model: RemoveNotificationModel) {
    // Ensure we have the necessary fields
    if (!model.uid || !model.createdDate) {
      return of(undefined);
    }

    return this.notificationService
      .notificationUidPatch(model.uid, {}) // Adjust to match your actual BE method name
      .pipe(
        tap(() => {
          this.state.update((currentState) => {
            const updatedNotifications = { ...currentState.notifications };

            // Determine the month-group key
            const monthKey = this.getMonth(model.createdDate!);

            // Filter out the removed notification from the correct month group
            if (updatedNotifications[monthKey]) {
              updatedNotifications[monthKey] = updatedNotifications[
                monthKey
              ].filter((notification) => notification.uid !== model.uid);

              // If that month group is now empty, remove the key
              if (!updatedNotifications[monthKey].length) {
                delete updatedNotifications[monthKey];
              }
            }

            return {
              ...currentState,
              notifications: updatedNotifications,
            };
          });
        }),
      );
  }

  private getMonth(date: string) {
    return DateTime.fromISO(date).startOf('month').toFormat('MMMM, yyyy');
  }

  private getGroupByMonth = ({
    createdDateTimeUTC,
  }: NotificationCreatedEvent) => this.getMonth(createdDateTimeUTC as string);

  private updateNotifications(
    notifications: Record<string, NotificationCreatedEvent[]>,
  ) {
    // First, merge the old and new notifications into one object
    const merged = _.mergeWith(
      {},
      this.notifications(),
      notifications,
      (objValue, srcValue) => {
        if (_.isArray(objValue)) {
          return objValue.concat(srcValue);
        }
        // If not an array, return undefined to indicate to lodash to use the default merge behavior
        return undefined;
      },
    );

    // Then, for each key in the merged object, remove duplicates
    for (const key in merged) {
      if (Object.prototype.hasOwnProperty.call(merged, key)) {
        const seen = new Map();
        merged[key] = merged[key].filter(
          (notification: NotificationCreatedEvent) => {
            const duplicate = seen.get(notification.uid);
            seen.set(notification.uid, true);
            return !duplicate;
          },
        );
      }
    }

    return merged;
  }

  clearAllNotifications() {
    return this.notificationService.notificationPut().pipe(
      tap(() => {
        this.state.update(() => ({
          ...this.INITIAL_STATE,
          status: LoadingStatus.Success,
        }));
      }),
    );
  }

  resetState() {
    this.state.update(() => this.INITIAL_STATE);
    this.pageNumber$.next(1);
  }

  private fetchNotifications(pageNumber: number) {
    return this.notificationService
      .notificationGet(pageNumber, this.DEFAULT_PAGE_SIZE)
      .pipe(
        tap((res) => {
          this.state.update((state) => ({
            ...state,
            hasNext: res.hasNext,
          }));
        }),
        map(({ notifications }) => {
          const updatedNotifications = this.updateNotifications(
            _.groupBy(notifications, this.getGroupByMonth),
          );

          return updatedNotifications;
        }),
      );
  }
}
